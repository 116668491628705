@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Titillium Web", sans-serif;
}

.link {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #98b66e;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hero-heading {
  font-weight: 700;
  color: #98b66e;
  text-align: center;
}

.flow-content > * + * {
  margin-top: 10px;
}
